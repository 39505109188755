var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',{staticClass:"pt-4"},[(_vm.loading)?_c('v-row',{staticClass:"mx-16"},_vm._l((3),function(index){return _c('v-col',{key:index,attrs:{"cols":"4"}},[_c('v-skeleton-loader',_vm._b({attrs:{"type":"list-item-three-line, list-item-three-line, avatar"}},'v-skeleton-loader',_vm.skeletonAttrs,false))],1)}),1):_c('v-row',{staticClass:"mx-md-16"},[_c('v-col',{class:_vm.$vuetify.breakpoint.mobile ? 'text-center' : ''},[_c('h3',{staticClass:"my-3 secondary-prosk text-center font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('testimonialsTitle'))+" ")])]),_c('hooper',{staticClass:"h-50 prosk-hopper",attrs:{"itemsToShow":3,"centerMode":true,"settings":_vm.hooperSettings},on:{"slide":_vm.onSlideChange}},[_vm._l((_vm.testimonials),function(item,n){return _c('slide',{key:n},[_c('v-card',{class:[
            {
              'primary_light': n === _vm.currentSlideIndex,
              'opacity':  !(n === _vm.currentSlideIndex)
            },
            'border-radius-medium card-with-triangle mb-3'
          ],attrs:{"elevation":"0","width":"600","minHeight":"350"}},[_c('v-card-title',{staticClass:"d-flex justify-content-center"},[(item.jobs)?_c('p',{staticClass:"m-0 font-size-16 pointer-cursor secondary--text text-center",on:{"click":function($event){return _vm.goToUserProfile(item.prosker_user)}}},[_vm._v(" "+_vm._s(item.prosker_user.first_name)+" "+_vm._s(item.prosker_user.last_name)+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.jobs.client_user.category_name[0]),expression:"item.jobs.client_user.category_name[0]"}]},[_vm._v("|")]),_vm._v(" "+_vm._s(item.jobs.client_user.category_name[0])+" ")]):_vm._e()]),_c('v-card-text',{staticClass:"pb-10 pt-5"},[_c('p',{staticClass:"ml-3 main_text_color--text font-weight-bold text-h5 text-center"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"ml-3 main_text_color--text text-center"},[_vm._v(_vm._s(item.content))]),_c('v-rating',{staticClass:"text-center",attrs:{"background-color":"secondary","half-increments":"","color":"secondary","hover":"","readonly":"","dense":""},model:{value:(item.rating),callback:function ($$v) {_vm.$set(item, "rating", $$v)},expression:"item.rating"}})],1)],1),_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('v-avatar',{attrs:{"width":"3rem","height":"3rem"}},[(item.jobs && item.jobs.client_user.profile_img)?_c('img',{staticClass:"img-fluid rounded-circle border border-dark avatar-40",class:{  'opacity':  !(n === _vm.currentSlideIndex)},attrs:{"src":item.jobs.client_user.profile_img,"alt":"user"}}):_c('img',{staticClass:"img-fluid rounded-circle avatar-40",attrs:{"src":require("@/assets/images/page-img/avatar.png"),"alt":"user"}})]),_c('p',{staticClass:"green-prosk text-center mb-8",class:{  'opacity':  !(n === _vm.currentSlideIndex)}},[_vm._v(" "+_vm._s(item.jobs.client_user.first_name)+" "+_vm._s(item.jobs.client_user.last_name)+" ")])],1)],1)}),_c('hooper-pagination',{attrs:{"slot":"hooper-addons"},slot:"hooper-addons"})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }