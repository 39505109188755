// src/mixins/userDataMixin.js
import { mapGetters } from 'vuex';
import { userTypeDictonary } from '@/Utils/user';

export default {
  computed: {
    ...mapGetters({
      authUser: 'auth/currentUser'
    })
  },
  methods: {
    trackUserData (eventName, extraData = {}) {
      const eventData = {
        event: eventName,
        ...extraData,
        userData: {
          visitorLoginState: this.authUser ? 'logged' : 'logged-out',
          ...(this.authUser && {
              id: this.authUser?.id,
              firstName: this.authUser?.first_name,
              lastName: this.authUser?.last_name,
              visitorEmail: this.authUser?.email,
              city: this.authUser?.addresses?.[0]?.city?.name,
              country: this.authUser?.addresses?.[0]?.country,
              state: this.authUser?.addresses?.[0]?.neighborhood,
              zipcode: this.authUser?.addresses?.[0]?.postal_code,
              birthdayDate: this.authUser?.birth_date,
              phone: this.authUser?.phone_number,
              type: userTypeDictonary[this.authUser.type_user],
              categories: this.authUser?.category_name,
              modalities: this.authUser?.skill_name
          })
        }
      };
      this.$gtm.trackEvent(eventData);
    }
  }
};
