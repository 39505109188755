<template>
  <v-parallax
    dark
    :src="imgUrl"
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        class="text-center"
        cols="12"
      >
        <h1 class="text-h4 text-md-h2 white--text mb-4">
          {{ title }}
        </h1>
        <save-button :save="onCtaClick" :size="'x-large'" :css-classes="'secondary-prosk'" :loading="false" :message="ctaText"/>
        <div class="mt-4">
          <router-link :to="{ name: textLinkRoute }">
            <span class="white--text text-subtitle-1">{{ textLinkText }}</span>
          </router-link>
        </div>
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';

export default {
  name: 'HeroBanner',
  components: { SaveButton },
  props: ['imgUrl', 'title', 'ctaText', 'textLinkText', 'onCtaClick', 'textLinkRoute']
};
</script>
