const mixin = {
  data () {
    return {};
  },
  methods: {
    showError (error, isSignInError, isUserError, callback, customText) {
      if (error) {
        let errorList = '';
        if (error.response && error.response.data.errors) {
          Object.entries(error.response.data.errors).forEach((err) => {
            errorList = errorList + this.$t(err[1][0]) + ', ';
          });
          errorList = errorList.substring(0, errorList.length - 2);
        } else if (error.message) {
          errorList = error.message;
        }
        if (!callback) {
          if (isSignInError) {
            this.$swal.fire({ title: this.$t('errorTitleSigIn'), text: errorList });
          } else if (isUserError) {
            this.$swal.fire({ title: this.$t('ErrorHasOccurred'), text: errorList });
          } else if (customText) {
            this.$swal.fire({ text: customText });
          } else {
            this.$swal.fire({ text: errorList });
          }
        } else {
          this.$swal.fire({ text: errorList }).then(() => callback());
        }
      }
    }
  },
  mounted () {}
};

export default mixin;
