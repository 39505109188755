<template>
  <v-main class="container-prosker">
    <v-col cols="12" class="m-0 p-0">
      <hero-banner
        :title="$t('clientBannerTitle')"
        :img-url="require('@/assets/images/page-img/home_client.png')"
        :on-cta-click="goToSignUp"
        :text-link-route="'social.proskers-page'"
        :cta-text="$t('begin')"
        :text-link-text="$t('offerAService')"
      />
    </v-col>
    <v-col cols="12" class="m-0 py-8">
      <p class="secondary-prosk mx-auto w-75 text-h5 text-md-h3 text-center">
        {{ $t('servicePlatformClient') }}
      </p>
    </v-col>
    <v-col cols="10" offset="1" class="text-center">
      <save-button :save="goToHowItWorks" :size="'large'" :loading="false" :message="$t('howDoesItWork')"/>
    </v-col>
    <v-col cols="12" class="m-0 py-8">
      <video-banner :video-url="video.url" :is-youtube="true" />
    </v-col>
    <v-col cols="12" class="m-0 py-8">
      <categories-carousel />
    </v-col>
    <v-col cols="12" class="m-0 py-8">
      <prosker-featured />
    </v-col>
    <v-col cols="12" class="m-0 py-8 grey lighten-2" v-if="showTestimonials">
      <Testimonials />
    </v-col>
  </v-main>
</template>

<script>
import Testimonials from '@/components/socialvue/testimonials/Testimonials';
import HeroBanner from '@/components/socialvue/banners/HeroBanner';
import VideoBanner from '@/components/socialvue/banners/VideoBanner';
import SaveButton from '@/components/socialvue/buttons/SaveButton';
import ProskerFeatured from '@/components/socialvue/carousel/ProskerFeatured.vue';
import CategoriesCarousel from '@/components/socialvue/carousel/CategoriesCarousel';
import { USER_TYPE_CLIENT } from '@/misc/constants';
import { mapActions } from 'vuex';

export default {
  name: 'HomeClients',
  components: {
    Testimonials,
    HeroBanner,
    SaveButton,
    VideoBanner,
    ProskerFeatured,
    CategoriesCarousel
  },
  data () {
    return {
      video: {
        url: 'https://www.youtube.com/embed/T-haSXDOoQA?si=_H-48lNTLki81vPP',
        title: this.$t('videoTitle'),
        ctaText: this.$t('wantToSignUp')
      }
    };
  },
  computed: {
    showTestimonials () {
      return process.env.VUE_APP_HIDE_TESTIMONIALS_CARROUSEL !== 'true';
    }
  },
  created () {
    this.setImportedConstants();
  },
  methods: {
    ...mapActions({
      updateSignUpUserType: 'auth/updateSignUpUserType'
    }),
    setImportedConstants () {
        this.$options.USER_TYPE_CLIENT = USER_TYPE_CLIENT;
    },
    goToHowItWorks () {
      this.$router.push({ name: 'social.how-works-clients' });
    },
    goToSignUp () {
      this.updateSignUpUserType(this.$options.USER_TYPE_CLIENT);
      this.$router.push({ name: 'auth1.sign-up1' });
    }
  }
};
</script>
