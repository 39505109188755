import CryptoJS from 'crypto-js';
import { AES_SECRET } from '@/misc/constants';

const mixin = {
    data () {
        return {
            userSearchedObject: {}
        };
    },
    created () {
        this.setImportedConstants();
    },
    methods: {
        setImportedConstants () {
          this.$options.AES_SECRET = AES_SECRET;
        },
        goToUserProfile (user, selectedTab, newTab) {
            const hashedId = CryptoJS.AES.encrypt(user.id.toString(), this.$options.AES_SECRET).toString();
            const queryParams = {};

            if (selectedTab) {
                queryParams.selectedTab = selectedTab;
            }

            if (newTab) {
                const routeData = this.$router.resolve({
                    name: 'social.user-profile',
                    params: { query: `${user.username}-${hashedId}` },
                    query: queryParams
                });
                window.open(routeData.href, '_blank');
            } else {
                this.$router.push({
                    name: 'social.user-profile',
                    params: { query: `${user.username}-${hashedId}` },
                    query: queryParams
                })
                .then(failure => {
                    console.log(failure);
                });
            }
        },
        getUserProfileUrl (user) {
            const hashedId = CryptoJS.AES.encrypt(user.id.toString(), this.$options.AES_SECRET).toString();
            return window.location.origin + `/perfil/${user.username}-${hashedId}`;
        }
    }
};

export default mixin;
