<template>
  <v-sheet elevation="4" class="bg-prosk-secondary white--text menu">
    <v-row v-if="loading" class="mx-16">
        <v-col v-for="index in 6" :key="index" cols="2">
            <v-skeleton-loader
                    dark
                    v-bind="skeletonAttrs"
                    type="image"
            ></v-skeleton-loader>
        </v-col>
    </v-row>
    <v-slide-group v-else
      class="p-md-4 p-0 my-slider pb-0 pt-4"
      :show-arrows="!$vuetify.breakpoint.mobile"
    >
      <template v-slot:next>
          <v-icon color="white">mdi-menu-right</v-icon>
      </template>
      <template v-slot:prev>
          <v-icon color="white">mdi-menu-left</v-icon>
      </template>
      <v-slide-item v-for="(category, i) in categoriesData" :key="i">
        <v-main class="ma-md-4 ma-1 text-center">
          <img
            :src="category.img_url"
            :class="
              $vuetify.breakpoint.mobile
                ? 'circle-cat-mobile'
                : 'circle-categories'
            "
            :alt="category.name"
            @click="sendGlobalSearch([category])"
            class="pointer-cursor bg-white margin"
          />
          <p
            class="mt-3 font font-weight-bold"
            @click="sendGlobalSearch([category])"
          >
            {{ category.name }}
          </p>
        </v-main>
      </v-slide-item>
    </v-slide-group>
  </v-sheet>
</template>
<script>
import axios from 'axios';
import errorDialog from '@/components/socialvue/mixins/errorDialog';

export default {
  name: 'CategoriesCarousel',
  mixins: [errorDialog],
  data () {
    return {
      errors: [],
      categoriesData: [],
      skeletonAttrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2
      },
      loading: false,
      globalSearchObject: {
        search: '',
        categoryIds: []
      }
    };
  },
  mounted () {
    this.getCategoriesData();
  },
  methods: {
    sendGlobalSearch (categories) {
      this.globalSearchObject.user = '';
      this.globalSearchObject.categoryIds = [];
      categories.forEach((category) => {
        this.globalSearchObject.categoryIds.push(category.id);
        category.children.forEach((subCategory) => {
          this.globalSearchObject.categoryIds.push(subCategory.id);
        });
      });
      this.$router.push({
        name: 'social.users',
        params: { query: JSON.stringify(this.globalSearchObject) }
      });
    },
    getCategoriesData () {
      this.loading = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/categories/with-subcategory`;
      axios
        .get(url)
        .then((response) => {
          response.data.data
            .filter((category) => !category.parent_id)
            .forEach((category) => {
              this.categoriesData.push(category);
            });
          this.categoriesData.sort(function (a, b) {
            if (a.id > b.id) {
              return 1;
            }
            if (a.id < b.id) {
              return -1;
            }
            return 0;
          });
          this.loading = false;
        })
        .catch((error) => {
          this.showError(error);
          this.loading = false;
        });
    }
  }
};
</script>
<style scoped>
 .menu {
/*    border-radius: 36px;*/
 }

  .font {
    font-size: 1rem;
 }

 .margin {
    margin: 0 48px;
 }

 @media (max-width: 780px) {
  .margin {
    margin: 0 12px;
  }
 }
</style>
