<template>
  <div>
    <h1 v-if="title" class="text-h4 secondary-prosk mb-4">
      {{ title }}
    </h1>

    <v-col cols="10" md="8" offset-md="2" offset="1">
      <video v-if="!isYoutube" class="w-100" controls>
        <source :src="videoUrl" type="video/mp4">
        Your browser does not support the video tag.
      </video>
      <div class="video-container ">
        <iframe width="560" height="315" :src="videoUrl" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" class="video-iframe" allowfullscreen></iframe>
      </div>
    </v-col>
  </div>
</template>

<script>
export default {
  name: 'VideoBanner',
  props: ['videoUrl', 'title', 'isYoutube']
};
</script>
