<template>
  <v-btn
    @click="save"
    :small="!size || size === 'small'"
    :large="size === 'large'"
    :x-large="size === 'x-large'"
    :block="size === 'block'"
    :loading="loading"
    :color="color ? color: '#59D79D'"
    :class="cssClasses ? cssClasses : 'white--text'"
  >
    {{message}}
  </v-btn>
</template>

<script>
export default {
  name: 'SaveButton',
  props: ['save', 'loading', 'message', 'size', 'color', 'cssClasses']
};
</script>
